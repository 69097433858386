<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t("sidebar.modules") }}</h4>
          </template>
          <template v-slot:headerAction>
            <b-button variant="primary" @click="$refs.modalModules.show()">{{
              $t("app.new")
            }}</b-button>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col sm="12" md="1" class="pr-1 mb-2">
                <b-form-select
                  :options="['5', '10', '20', '50', '100']"
                  v-model="filter.per_page"
                  size="sm"
                  @input="getData()"
                ></b-form-select>
              </b-col>
              <b-col lg="3" md="6" sm="12" class="ml-auto mb-2">
                <b-form-group>
                  <!-- <b-form-input
                    style="height: 35px"
                    :disabled="
                      !Array.isArray(getGrades.data) ||
                      (getGrades.data.length === 0 && gradesLoading === false)
                    "
                    v-model="filter.search"
                    type="search"
                    :placeholder="$t('app.search')"
                  ></b-form-input> -->
                </b-form-group>
              </b-col>

              <b-col md="12" class="table-responsive">
                <b-table
                  class="table mb-0 table-borderless"
                  :items="getModules.modules"
                  :fields="columns"
                  :per-page="filter.per_page"
                  :busy="
                    !Array.isArray(getModules.modules) ||
                    getModules.modules.length === 0
                      ? true
                      : false || gradesLoading == true
                  "
                  @filtered="onFiltered"
                >
                  <template #table-busy>
                    <div class="text-center text-danger my-2">
                      <div v-if="gradesLoading">
                        <b-spinner type="grow" class="align-middle"></b-spinner>
                        <strong>{{ $t("app.loading") }}...</strong>
                      </div>
                      <span class="h5" v-else>{{ $t("app.not-found") }}</span>
                    </div>
                  </template>

                  <template v-slot:cell(action)="data">
                    <b-button
                      variant=" iq-bg-warning rounded"
                      class="mr-1"
                      size="sm"
                      @click="$refs.modalModules.show(data.item)"
                      ><i class="ri-ball-pen-fill ml-1"></i
                    ></b-button>
                    <b-button
                      @click="deleteItem(data.item.id)"
                      variant=" iq-bg-danger rounded"
                      size="sm"
                      ><i class="ri-delete-bin-line ml-1"></i
                    ></b-button>
                  </template>
                </b-table>
                <!-- <b-pagination
                  class="mt-3 float-right"
                  v-model="filter.page"
                  :total-rows="datamodule.total"
                  :per-page="datamodule.per_page"
                  first-number
                  pills
                  size="sm"
                  @change="getData"
                ></b-pagination>
                <p class="mt-3">
                  {{ $t("app.registers") }} {{ datamodule.to }}
                  {{ $t("app.of") }} {{ datamodule.total }}. Total
                  {{ datamodule.total }}
                </p> -->
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <modal-form ref="modalModules" @refresh="getData()" />
  </b-container>
</template>
<script>
import { core } from "@/config/pluginInit";
import modalForm from "./modalForm.vue";
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";

export default {
  name: "Modules",
  components: {
    modalForm,
  },
  mounted() {
    core.index();
    this.debouncedGetAnswer = _.debounce(this.getData, 500);
    this.getData();
  },
  watch: {
    "filter.search": {
      handler() {
        this.debouncedGetAnswer();
      },
    },
  },
  methods: {
    ...mapActions({
      fetchModules: "fetchModules",
      deleteModules: "deleteModules",
    }),
    getData(page = 1) {
      this.filter.page = page;
      this.fetchModules();
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    deleteItem(id) {
      this.$bvModal
        .msgBoxConfirm(this.$t("app.body-confirm-delete"), {
          title: this.$t("app.title-confirm-delete"),
          size: "sm",
          buttonSize: "sm",
          okVariant: "success rounded",
          cancelVariant: "outline-danger rounded",
          okTitle: this.$t("app.yes"),
          cancelTitle: this.$t("app.not"),
          headerClass: "text-center",
          bodyClass: "text-center text-danger",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then(async (value) => {
          if (value) {
            const resp = await this.deleteModules(id);
            this.getData();
            if (resp?.status == 200) {
              core.showSnackbar("success", resp.data.message);
            }
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
  },
  data() {
    return {
      filter: {
        paginate: true,
        page: 1,
        per_page: 10,
        search: null,
      },
      datamodule: {
        current_page: 1,
        data: [
          {
            id: "eyJpdiI6ImF3ajJpWVp3MzNQS3BkVXlvOEZOL0E9PSIsInZhbHVlIjoiMWk4S2hhMmQ3d0hOdnE5SVJoc25Xdz09IiwibWFjIjoiZWQ2OGVhMmE1ZDQxN2Q1MjNlNzZlY2JmYWI2Yzk4YmIxMWI0NTJmYWNkNzUyYWFlZjA3ODdjODAwNzRiZGIwZCIsInRhZyI6IiJ9",
            name: "Documents",
            description: "Documentos a descargar",

            created_at: "2023-04-06",
          },
          {
            id: "eyJpdiI6IF3ajJpWVp3MzNQS3BkVXlvOEZOL0E9PSIsInZhbHVlIjoiMWk4S2hhMmQ3d0hOdnE5SVJoc25Xdz09IiwibWFjIjoiZWQ2OGVhMmE1ZDQxN2Qda1MjNlNzZlY2JmYWI2Yzk4YmIxMWI0NTJmYWNkNzUyYWFlZjA3ODdjODAwNzRiZGIwZCIsInRhZyI6IiJ9",
            name: "Blog",
            description: "Seccion de blog",

            created_at: "2023-04-06",
          },
          {
            id: "eyJpdiI6IF3ajJpWVp3MzNQS3BkVXlvOEZOL0E9PSIsInZhbHVlIjoiMWk4S2hhMmQ3d0hOdnE5SVJoc25Xdz09IiwibWFjIjoiZWQ2OGVhMmE1sZDQxN2Qda1MjNlNzZlY2JmYWI2Yzk4YmIxMWI0NTJmYWNkNzUyYWFlZjA3ODdjODAwNzRiZGIwZCIsInRhZyI6IiJ9",
            name: "Enfermeria",
            description: "Seccion de enfermeria",

            created_at: "2023-04-06",
          },
        ],
        first_page_url: null,
        from: 1,
        last_page: 2,
        last_page_url: null,
        links: [
          { url: null, label: "&laquo; Anterior", active: false },
          {
            url: null,
            label: "1",
            active: true,
          },
          {
            url: "http://localhost:8000/api/v1/grades?page=2",
            label: "2",
            active: false,
          },
          {
            url: "http://localhost:8000/api/v1/grades?page=2",
            label: "Siguiente &raquo;",
            active: false,
          },
        ],
        next_page_url: null,
        // path: "http://localhost:8000/api/v1/grades",
        per_page: 10,
        prev_page_url: null,
        to: 1,
        total: 3,
      },
      totalRows: 1,
    };
  },
  computed: {
    ...mapGetters({
      getModules: "getModules",
      gradesLoading: "gradesLoading",
    }),
    columns() {
      return [
        { label: this.$t("module.name"), key: "name", class: "text-center" },
        {
          label: this.$t("module.description"),
          key: "description",
          class: "text-center",
        },
        { label: this.$t("app.action"), key: "action", class: "text-center" },
      ];
    },
  },
};
</script>
